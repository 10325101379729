
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface add_role{
    name:string
    desc:string
    auth:string
}
export interface role extends add_role{
    id:Id
    create_time:string
    update_time:string
}

export interface menu{
    id:number
    title:string
    child:{id:number,title:string}[]
}

class HttpRole extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<role>>({...data})
	}

    get_info = (id:number) => {
        return this.get<role>({},`/admin/role/${id}/`)
    }

    create = (data:add_role) => {
        return this.post(data)
    }

    edit = ({id,...data}: role) => {
        return this.patch(data,`/admin/role/${id}/`)
    }

    del_one = (id:number) => {
        return this.delete(id)
    }

    get_menu = () => {
        return this.get<menu[]>({},`/menu/`)
    }
}

export const api_role =  new HttpRole('/admin/role/')
