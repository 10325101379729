





























































import { Vue, Component } from "vue-property-decorator";
import { api_role, role } from "@/http/role";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
@Component({
  components: {
    PageHeader,
  },
})
export default class extends Vue {
  menu: any = [];
  checkAll: any = [];
  checkedAuth: any = [];
  isIndeterminate: any = [];

  info: role = {
    id: "",
    name: "",
    desc: "",
    auth: "",
    create_time: "",
    update_time: "",
  };
  //   权限列表
  authList: any = [
    {
      name: "首页",
      value: "1",
    },
    {
      name: "门店管理",
      value: "2",
    },
    {
      name: "商品管理",
      value: "3",
    },
    {
      name: "出入库管理",
      value: "4",
    },
    {
      name: "订单管理",
      value: "5",
    },
    {
      name: "用户管理",
      value: "6",
    },
    {
      name: "账号管理",
      value: "7",
    },
  ];
  rules = {
    name: [{ required: true, message: "请输入" }],
  };

  handleCheckAllChange(index: number) {
    // let val = this.checkAll[index];
    // this.$set(
    //   this.checkedCities,
    //   index,
    //   val
    //     ? this.menu[index].child.map((item: any) => {
    //         return item.id;
    //       })
    //     : []
    // );
    // this.$set(this.isIndeterminate, index, false);
  }
  handleCheckedCitiesChange(index: number) {
    // let checkedCount = this.checkedCities[index].length;
    // this.$set(
    //   this.checkAll,
    //   index,
    //   checkedCount === this.menu[index].child.length
    // );
    // this.$set(
    //   this.isIndeterminate,
    //   index,
    //   checkedCount > 0 && checkedCount < this.menu[index].child.length
    // );
  }

  async submit() {
    // let arr = this.checkedCities.flat(Infinity);
    // this.checkedCities.forEach((e: [], i: number) => {
    //   if (e.length > 0) {
    //     arr.push(this.menu[i].id);
    //   }
    // });
    let str = this.checkedAuth.join(",");
    this.info.auth = str;
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    if (this.id) {
      await api_role.edit(this.info);
    } else {
      await api_role.create(this.info);
    }
    this.$message.success("修改成功");
    this.$router.go(-1);
  }

  async get_info() {
    this.info = await api_role.get_info(this.id as number);
    let arr = this.info.auth.split(",");
    this.checkedAuth = arr;
    // this.menu.forEach((items: any, indexs: number) => {
    //   items.child.forEach((item: any, index: number) => {
    //     if (arr.includes(item.id + "")) {
    //       this.checkedCities[indexs].push(item.id);
    //     }
    //   });
    //   this.checkAll[indexs] =
    //     this.checkedCities[indexs].length === this.menu[indexs].child.length;
    //   this.isIndeterminate[indexs] =
    //     this.checkedCities[indexs].length > 0 &&
    //     this.checkedCities[indexs].length < this.menu[indexs].child.length;
    // });
  }

  get id(): undefined | number {
    return this.$route.query.id as any;
  }

  async created() {
    // this.menu = await api_role.get_menu();
    // this.checkedCities = this.menu.map(() => {
    //   return [];
    // });
    if (this.id === undefined) return;
    this.get_info();
  }
  authChange(){

    console.log(this.checkedAuth)
  }
}
